import { useAppStateSelector, useAppActionDispatch } from '@redux/hooks';
import { setIsUserLoggedIn } from '@xFrame4/redux/userSlice';
import { AuthUser } from '@xFrame4/business/users/AuthUser';
import { useEffect, useState } from 'react';

/**
 * Hook: try to log in the user from the JWT in the local storage.
 * 
 * @param deps 
 * @returns 
 */
export function useLoginFromJWT(deps: React.DependencyList = [])
{
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);
    const dispatch = useAppActionDispatch();

    async function loginFromJWT()
    {
        setIsLoading(true);

        if (!isUserLoggedIn)
        {
            let success = await AuthUser.loginFromJWT();
            if (success && AuthUser.current != null) 
            {
                dispatch(setIsUserLoggedIn(success && AuthUser.current != null));
            }
        }

        setIsLoading(false);
    }

    useEffect(() =>
    {
        loginFromJWT();
    }, deps);

    return [isLoading, isUserLoggedIn];
}