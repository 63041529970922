import { FunctionComponent, useState } from 'react';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useTranslate, useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import Pixie, { PixieLanguage } from '@business/pixies/Pixie';
import { Row, Col } from 'react-bootstrap';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import { PixieStatus, PixieTypeOfContent } from '@business/pixies/generated/Pixie.generated';
import PixieTranslation from '@business/pixies/PixieTranslation';

interface PixieAddEditFormProps extends EntityAddEditFormProps<Pixie>
{

}

const PixieAddEditForm: FunctionComponent<PixieAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Pixie>(props.entity ?? new Pixie());
    const [title, setTitle] = useState<string>(entity.primaryTranslation?.title ?? '');
    const [description, setDescription] = useState<string>(entity.primaryTranslation?.description ?? '');
    const [thumbnail, setThumbnail] = useState<File>();
    const [images, setImages] = useState<FileList>();

    /** Save */
    const onSave = async () =>
    {
        if (mode === AddEditFormMode.Add)
        {
            if (entity.typeOfContent === PixieTypeOfContent.IMAGES)
            {
                let pixie = await Pixie.createPixieWithImages(
                    entity.primaryLanguage,
                    title,
                    thumbnail!,
                    images!,
                    description
                );

                return pixie != null;
            }
            else if (entity.typeOfContent === PixieTypeOfContent.VIDEO)
            {
                let pixie = await Pixie.createPixieWithVideo(
                    entity.primaryLanguage,
                    title,
                    entity.content,
                    thumbnail!,
                    description
                );

                return pixie != null;
            }
        }
        else
        {
            // save pixie
            let success = await entity.save();
            
            if (success)
            {
                // save translation
                let translation = PixieTranslation.createFromGraphQL(entity.primaryTranslation) as PixieTranslation;
                translation.title = title;
                translation.description = description;
                success = await translation .save();

                if (success)
                {
                    // save thumbnail
                    if (thumbnail)
                    {
                        success = await entity.saveThumbnail(thumbnail);
                    }
                }
            }
        }

        return false;
    }

    /** Tab: general */
    let cmpTabGeneral = <>
        <Row>
            <Col lg={4}>
                <FieldEditor 
                    type={FieldEditorType.Select}
                    field="typeOfContent"
                    label="Type of content"
                    isRequired={true}
                    dataSource={enumToDataSource(PixieTypeOfContent)}
                    value={entity.typeOfContent}
                    onValueChanged={(field, value) => updateEntityField(field, value.value)}
                />
            </Col>
            <Col lg={4}>
                <FieldEditor
                    type={FieldEditorType.Select}
                    field="status"
                    label="Status"
                    isRequired={true}
                    dataSource={enumToDataSource(PixieStatus)}
                    value={entity.status}
                    onValueChanged={(field, value) => updateEntityField(field, value.value)}
                />
            </Col>
            <Col lg={4}>
                <FieldEditor
                    type={FieldEditorType.Select}
                    field="primaryLanguage"
                    label="Primary language"
                    isRequired={true}
                    dataSource={enumToDataSource(PixieLanguage)}
                    value={entity.primaryLanguage}
                    onValueChanged={(field, value) => updateEntityField(field, value.value)}
                />
            </Col>
        </Row>
        <Row className={entity.typeOfContent === PixieTypeOfContent.VIDEO ? '' : 'd-none'}>
            <Col>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="content"
                    label="Content"
                    isRequired={entity.typeOfContent === PixieTypeOfContent.VIDEO}
                    value={entity.content}
                    onValueChanged={(field, value) => updateEntityField(field, value)}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="title"
                    label="Title"
                    isRequired={true}
                    value={title}
                    onValueChanged={(field, value) => setTitle(value)}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FieldEditor
                    type={FieldEditorType.Textarea}
                    field="description"
                    label="Description"
                    value={description}
                    onValueChanged={(field, value) => setDescription(value)}
                />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.File}
                    field="thumbnail"
                    label="Thumbnail"
                    isRequired={mode === AddEditFormMode.Add}
                    value={thumbnail}
                    onValueChanged={(field, value) => setThumbnail(value)}
                />
            </Col>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.File}
                    field="images"
                    label="Images"
                    isRequired={mode === AddEditFormMode.Add && entity.typeOfContent === PixieTypeOfContent.IMAGES}
                    isVisible={entity.typeOfContent === PixieTypeOfContent.IMAGES}
                    allowMultipleFileUpload={true}
                    value={images}
                    onValueChanged={(field, value) => setImages(value)}
                />
            </Col>
        </Row>
    </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm<Pixie>
                entity={entity}
                mode={mode}
                titleAdd={'Add pixie'}
                titleEdit={t('EDIT') + ' ' + entity.primaryTranslation?.title}
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default PixieAddEditForm;