import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import User from '@xFrame4/business/users/User';


export class FacebookUserProfileGeneratedEntityManager<B extends FacebookUserProfileGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'FacebookUserProfile',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'user', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'userId', inputProperty: 'user.id', relatedManager: User.manager },
				{ name: 'appId', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'appSecret', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'accessToken', type: BusinessEntityFieldType.VarChar },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'facebookUserProfiles',
        });
    }

}
export class FacebookUserProfileGenerated extends BusinessEntity
{
	user!: User;
	appId!: string;
	appSecret!: string;
	accessToken: string | null = null;

}

export default FacebookUserProfileGenerated;