import React, { useState } from 'react';
import { FacebookPost } from '@business/scheduler/FacebookPost';
import { GridColumns } from '@mui/x-data-grid';
import { FunctionComponent } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { useLoad } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import ScheduleLanguage from '@business/scheduler/ScheduleLanguage';
import FacebookPostAddEditForm from './FacebookPostAddEditForm';
import ScheduleABunchOfPostsForm from './ScheduleABunchOfPostsForm';
import { FacebookPostTypeOfPost } from '@business/scheduler/generated/FacebookPost.generated';

interface FacebookPostViewProps
{

}

const FacebookPostView: FunctionComponent<FacebookPostViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<FacebookPost>(FacebookPost);
    const [scheduleLanguages, setScheduleLanguages] = useState<ScheduleLanguage[]>([]);
    const [isScheduleBunchOpen, setIsScheduleBunchOpen] = useState<boolean>(false);

    useLoad(async () => {
        // Schedule languages
        let languagesResult = await ScheduleLanguage.manager.load();
        if (languagesResult != null) setScheduleLanguages(languagesResult.entities);
    });
    
    /** Grid columns. */
    const columns = React.useMemo<GridColumns<FacebookPost>>(
        () => [
            { field: 'id', headerName: 'ID', width: 50 },
            { field: 'typeOfPost', headerName: 'Type', width: 70 },
            { field: 'scheduleDate', headerName: 'Schedule date' },
            { field: 'phase', headerName: 'Phase', width: 50, valueGetter: params => params.row.phase?.sequenceNo },
            { field: 'scheduleTime', headerName: 'Schedule time' },
            { field: 'page', headerName: 'Page', valueGetter: params => params.row.page?.name, flex: 1 },
            { field: 'language', headerName: 'Language', width: 80, valueGetter: params => params.row.page?.language?.code },
            {
                field: 'text', headerName: 'Text', flex: 2, renderCell: params =>
                {
                    return <div className="small">{params.row.text}</div>
                }
            },
            {
                field: 'media', headerName: 'Media', renderCell: params => 
                {
                    if (params.row.mediaFileUrl != null && params.row.mediaFileUrl != '')
                    {
                        let link = params.row.typeOfPost == FacebookPostTypeOfPost.LINK ? params.row.link : params.row.mediaFileUrl;
                        return (
                            <a href={link as string} target="_blank" rel="noreferrer" className="cursor-pointer">
                                <img src={params.row.mediaFileUrl as string} width={100} height={100} />
                            </a>
                        );
                    }
                    else
                    {
                        return <></>;
                    }
                }
            },
            {
                field: 'isPosted', headerName: 'Status', renderCell: params =>
                {
                    if (params.row.isPosted)
                    {
                        return (
                            <a target="_blank" rel="noreferrer" href={'https://www.facebook.com/' + params.row.facebookPostId}>Posted</a>
                        );
                    }
                    else
                    {
                        return <>Scheduled</>;
                    }
                }
            },
        ],
        [],
    );
    
    /** Render */
    return (
        <>
            {addEditEntity &&
            <FacebookPostAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            {isScheduleBunchOpen &&
            <ScheduleABunchOfPostsForm 
                onClose={() => setIsScheduleBunchOpen(false)}
            />
            }
            
            <EntityView<FacebookPost>
                isVisible={addEditEntity === undefined && !isScheduleBunchOpen}
                id="facebook-posts"
                title="Posts"
                manager={FacebookPost.manager}
                baseFilter={{}}
                pageSize={10}
                buttonsLeft={[
                    {
                        text: 'Schedule post',
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    },
                    {
                        text: 'Schedule a bunch of posts',
                        variant: 'danger',
                        onClick: () => setIsScheduleBunchOpen(true)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    { field: 'page_Name_Icontains', label: 'Page', type: FieldEditorType.Text },
                    { field: 'scheduleDate', label: 'Schedule date', type: FieldEditorType.Date },
                    { field: 'page_Language_Code', label: 'Language', type: FieldEditorType.Select, dataSource: scheduleLanguages, dataSourceValueMember: 'code', dataSourceDisplayMember: 'name' },
                    { field: 'typeOfPost_In', label: 'Type of post', type: FieldEditorType.List, dataSource: enumToDataSource(FacebookPostTypeOfPost) }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => 'Delete post: ' + entity.id + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default FacebookPostView;