import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import FacebookPage from '@business/scheduler/FacebookPage';
import ScheduleLanguage from '@business/scheduler/ScheduleLanguage';
import SchedulePhase from '@business/scheduler/SchedulePhase';
import { useLoad, useTranslate, useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';

interface FacebookPageAddEditFormProps extends EntityAddEditFormProps<FacebookPage>
{

}

const FacebookPageAddEditForm: FunctionComponent<FacebookPageAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<FacebookPage>(props.entity ?? new FacebookPage());
    const [scheduleLanguages, setScheduleLanguages] = useState<ScheduleLanguage[]>([]);
    const [schedulePhases, setSchedulePhases] = useState<SchedulePhase[]>([]);
    const [selectedSchedulePhases, setSelectedSchedulePhases] = useState<SchedulePhase[]>([]);
    const [timeZones, setTimeZones] = useState<{[index: string]: any}[]>([]);

    useLoad(async () =>
    {
        // Schedule languages
        let languagesResult = await ScheduleLanguage.manager.load();
        if (languagesResult != null) setScheduleLanguages(languagesResult.entities);

        // Schedule phases
        let phasesResult = await SchedulePhase.manager.load();
        if (phasesResult != null) setSchedulePhases(phasesResult.entities);

        // Selected schedule phases
        let selectedSchedulePhasesResult = await entity.phases.load();
        if (selectedSchedulePhasesResult != null) setSelectedSchedulePhases(selectedSchedulePhasesResult.entities);

        // Time zones
        let timezonesArray: {[index: string]: any}[] = [];
        for (let i = -11; i < 12; i++)
        {
            timezonesArray.push({
                value: i,
                display: i
            });
        }
        setTimeZones(timezonesArray);
    });

    /** Save */
    const save = async () =>
    {
        // save the entity
        let success = await entity.save();

        if (success)
        {
            // save the selected phases
            await entity.phases.set(selectedSchedulePhases);
        }

        return success;
    }

    /** Tab: General */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="name"
                    label="Name"
                    isRequired={true}
                    value={entity?.name}
                    onValueChanged={(field, value) => updateEntityField(field, value)}
                />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="language"
                        label="Language"
                        isRequired={true}
                        dataSource={scheduleLanguages}
                        dataSourceValueMember="code"
                        dataSourceDisplayMember="name"
                        value={entity?.language}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="timezone"
                        label="Timezone"
                        isRequired={true}
                        dataSource={timeZones}
                        value={entity?.timezone}
                        onValueChanged={(field, value) => updateEntityField(field, value?.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="trackingCode"
                        label="Tracking code"
                        value={entity?.trackingCode}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.List}
                        field="phases"
                        label="Phases"
                        dataSource={schedulePhases}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="sequenceNo"
                        value={selectedSchedulePhases}
                        onValueChanged={(field, value) => setSelectedSchedulePhases(value)}
                    />
                </Col>
            </Row>
        </>
    
    /** Render */
    return (
        <>
            <EntityAddEditForm<FacebookPage>
                entity={entity}
                mode={mode}
                titleAdd="Add Facebook page"
                titleEdit={t('EDIT') + ': ' + entity?.nameDetailed}
                onSave={() => save()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default FacebookPageAddEditForm;