import client from '@graphql/apollo-client';
import { PostToPageMutation, PostToPageDocument, SchedulePostMutation, SchedulePostMutationVariables, SchedulePostDocument, ScheduleABunchOfPostsMutation, ScheduleABunchOfPostsDocument } from '@graphql/generated';
import { BusinessEntityFilter } from '@xFrame4/business/base/BusinessEntity';
import FacebookPageGenerated, { FacebookPageGeneratedEntityManager } from './generated/FacebookPage.generated';
import SchedulePhase from './SchedulePhase';
import { FacebookPostTypeOfPost } from './generated/FacebookPost.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class FacebookPageEntityManager extends FacebookPageGeneratedEntityManager<FacebookPage>
{
    constructor()
    {
        super({
            createEntity: () => new FacebookPage(),
            fields: [
                { name: 'nameDetailed', type: BusinessEntityFieldType.VarChar, isInput: false},
                { name: 'phasesList', type: BusinessEntityFieldType.Custom, isInput: false, relatedManager: SchedulePhase.manager},
            ]
        });
    }
}

export class FacebookPage extends FacebookPageGenerated
{
    nameDetailed!: string;
    phasesList!: SchedulePhase[];
    
    static manager: FacebookPageEntityManager = new FacebookPageEntityManager();

    /**
     * Get the pages associated with the user.
     * 
     * @param userId 
     */
    static async getPagesForUser(userId: number, filter?: BusinessEntityFilter)
    {
        // Filter
        if (filter === undefined) filter = {};
        filter.profile_User_Id = userId;

        let result = await FacebookPage.manager.load(filter);
        if (result != null)
        {
            return result.entities;
        }
        else
        {
            return [];
        }
    }

    /**
     * Activate/deactivate a page.
     * 
     * @param isActive Activate or not?
     */
    async setIsActive(isActive: boolean)
    {
        this.isActive = isActive;
        await this.save();
    }

    /**
     * Post something to the Facebook page's feed.
     * 
     * @param link 
     * @param photoFile 
     * @param message 
     */
    async postToFacebook(link?: string, photoFile?: File, message?: string)
    {
        let { data } = await client.mutate<PostToPageMutation>({
            mutation: PostToPageDocument,
            variables: {
                input: {
                    pageId: this.id,
                    link: link,
                    photo: photoFile,
                    message: message
                }
            }
        });

        return data?.postToPage?.success as boolean;
    }

    /**
     * Schedule a post for the page.
     * 
     * @param typeOfPost The type of the post
     * @param date The schedule date.
     * @param phase The schedule phase.
     * @param link The link. A link will be scheduled if this parameter is present.
     * @param photoFile The photo. A photo will be scheduled if this parameter is present.
     * @param message An optional message.
     */
    async schedulePost(typeOfPost: FacebookPostTypeOfPost, date: string, phase: SchedulePhase, link?: string, photoFile?: File, message?: string)
    {
        let variables = {
            input: {
                typeOfPost: typeOfPost,
                pageId: this.id as number,
                date: date,
                phaseId: phase.id as number,
                link: link,
                photo: photoFile,
                message: message
            }
        };
        
        try
        {
            let { data } = await client.mutate<SchedulePostMutation, SchedulePostMutationVariables>({
                mutation: SchedulePostDocument,
                variables: variables
            });

            if (data?.schedulePost?.success)
            {
                const { FacebookPost } = await import('./FacebookPost');
                return FacebookPost.createFromGraphQL(data.schedulePost.post);
            }
            else
            {
                return null;
            }
        }
        catch (e)
        {
            console.log('Error scheduling post. Document name: SchedulePostDocument.', variables, e);
            return null;
        }
    }

    /**
     * Schedule a bunch of photos.
     * 
     * @param pages 
     * @param startDate 
     * @param phases 
     * @param links 
     * @param photoFiles Photos FileList
     */
    static async scheduleABunchOfPosts(startDate: string, pages: FacebookPage[], phases: SchedulePhase[], links?: string[], photoFiles?: FileList, getMessagesFromFileNames?: boolean)
    {
        let variables = {
            input: {
                startDate: startDate,
                pageIds: pages.map(page => page.id),
                phaseIds: phases.map(phase => phase.id),
                links: links,
                photos: photoFiles,
                getMessagesFromFileNames: getMessagesFromFileNames
            }
        }
        
        try
        {
            let { data } = await client.mutate<ScheduleABunchOfPostsMutation>({
                mutation: ScheduleABunchOfPostsDocument,
                variables: variables
            });

            return data?.scheduleABunchOfPosts?.success as boolean;
        }
        catch (e)
        {
            console.log('Error scheduling bunch of posts. Document name: ScheduleABunchOfPostsDocument.', variables, e);
            return false;
        }
    }
}

export default FacebookPage;