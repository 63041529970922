import ScheduleLanguageGenerated, { ScheduleLanguageGeneratedEntityManager } from './generated/ScheduleLanguage.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class ScheduleLanguageEntityManager extends ScheduleLanguageGeneratedEntityManager<ScheduleLanguage>
{
    constructor()
    {
        super({
            createEntity: () => new ScheduleLanguage(),
        });
    }
}

export class ScheduleLanguage extends ScheduleLanguageGenerated
{
    static manager: ScheduleLanguageEntityManager = new ScheduleLanguageEntityManager();
}

export default ScheduleLanguage;