import SchedulePhaseGenerated, { SchedulePhaseGeneratedEntityManager } from './generated/SchedulePhase.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class SchedulePhaseEntityManager extends SchedulePhaseGeneratedEntityManager<SchedulePhase>
{
    constructor()
    {
        super({
            createEntity: () => new SchedulePhase(),
        });
    }
}

export class SchedulePhase extends SchedulePhaseGenerated
{
    static manager: SchedulePhaseEntityManager = new SchedulePhaseEntityManager();
}

export default SchedulePhase;