import FacebookPostGenerated, { FacebookPostGeneratedEntityManager } from './generated/FacebookPost.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class FacebookPostEntityManager extends FacebookPostGeneratedEntityManager<FacebookPost>
{
    constructor()
    {
        super({
            createEntity: () => new FacebookPost(),
            fields: [
                { name: 'mediaFileUrl', type: BusinessEntityFieldType.VarChar, isInput: false },
                { name: 'text', type: BusinessEntityFieldType.VarChar, isInput: false },
                { name: 'photo', type: BusinessEntityFieldType.File, isInFieldDetails: false, inputProperty: 'photoFile' }
            ]
        });
    }
}

export class FacebookPost extends FacebookPostGenerated
{
    mediaFileUrl: string = '';
    text: string = '';
    
    static manager: FacebookPostEntityManager = new FacebookPostEntityManager();
}

export default FacebookPost;