import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Pixie from '@business/pixies/Pixie';


export class PixieTranslationGeneratedEntityManager<B extends PixieTranslationGenerated> extends EntityTranslationManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'PixieTranslation',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'pixie', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'pixieId', inputProperty: 'pixie.id', relatedManager: Pixie.manager },
				{ name: 'title', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'description', type: BusinessEntityFieldType.Text },
				{ name: 'autoPostCount', type: BusinessEntityFieldType.Integer },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'pixieTranslations',
        });
    }

}
export class PixieTranslationGenerated extends BusinessEntityTranslation
{
	pixie!: Pixie;
	title!: string;
	description: string = '';
	autoPostCount: number = 0;

}

export default PixieTranslationGenerated;