import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import FacebookPage from '@business/scheduler/FacebookPage';
import SchedulePhase from '@business/scheduler/SchedulePhase';

export enum FacebookPostTypeOfPost
{
    PHOTO = 'PHOTO',
    LINK = 'LINK',
    VIDEO = 'VIDEO',

}

export class FacebookPostGeneratedEntityManager<B extends FacebookPostGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'FacebookPost',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'typeOfPost', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'page', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'pageId', inputProperty: 'page.id', relatedManager: FacebookPage.manager },
				{ name: 'scheduleDate', type: BusinessEntityFieldType.Date, isRequiredInput: true },
				{ name: 'scheduleTime', type: BusinessEntityFieldType.Time, isInput: false },
				{ name: 'phase', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'phaseId', inputProperty: 'phase.id', relatedManager: SchedulePhase.manager },
				{ name: 'message', type: BusinessEntityFieldType.Text },
				{ name: 'link', type: BusinessEntityFieldType.VarChar },
				{ name: 'mediaFile', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'isPosted', type: BusinessEntityFieldType.Boolean, isInput: false },
				{ name: 'facebookPostId', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'reactionCount', type: BusinessEntityFieldType.Integer, isInput: false },
				{ name: 'shareCount', type: BusinessEntityFieldType.Integer, isInput: false },
				{ name: 'ogTags', type: BusinessEntityFieldType.Json, isInput: false },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'facebookPosts',
        });
    }

}
export class FacebookPostGenerated extends BusinessEntity
{
	typeOfPost!: string;
	page!: FacebookPage;
	scheduleDate!: string;
	scheduleTime!: string;
	phase!: SchedulePhase;
	message: string = '';
	link: string = '';
	mediaFile: string = '';
	isPosted: boolean = false;
	facebookPostId: string = '';
	reactionCount: number = 0;
	shareCount: number = 0;
	ogTags: any = {};

}

export default FacebookPostGenerated;