import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import React, { FormEvent, useState } from 'react';
import { FunctionComponent } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useAppStateSelector, useAppActionDispatch } from '@redux/hooks';
import { setIsUserLoggedIn } from '@xFrame4/redux/userSlice';
import AuthContainer from './AuthContainer';
import { useTranslate } from '@xFrame4/components/Hooks';
import { AuthUser } from '@xFrame4/business/users/AuthUser';
import { Link, useNavigate } from 'react-router-dom';

interface RegistrationViewProps
{

}

const RegistrationView: FunctionComponent<RegistrationViewProps> = (props) =>
{
    const t = useTranslate();
    const navigate = useNavigate();
    const dispatch = useAppActionDispatch();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);
    
    /** Try to register the user and create a farm for it. */
    const register = async() => {
        let result = await AuthUser.register(email, password);

        if (result)
        {
            dispatch(setIsUserLoggedIn(true));
            navigate('/');
        }
    }; 

    /** Form submit */
    const onSubmit = (event: FormEvent<HTMLFormElement>) =>
    {
        event.preventDefault();
        event.stopPropagation();

        // Validate
        const form = event.currentTarget;
        if (form.checkValidity() === true) // Standard validation
        {
            if (password == password2)
            {
                // Register
                register();
            }
        }

        setValidated(true);
    };

    /** Bottom links */
    const bottomLinks = 
    <Row className="mt-3">
        <Col className="text-center">
            <p className="text-muted pb-5">
                {t('REGISTER_ALREAD_HAS_ACCOUNT')}{' '}
                <Link to={'/login'} className="text-muted ms-1">
                    <b>{t('SIGN_IN')}</b>
                </Link>
            </p>
        </Col>
    </Row>
    
    /** Render */
    return (
        <AuthContainer bottomLinks={bottomLinks}>
            <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('SIGN_UP')}</h4>
                <p className="text-muted mb-4">
                    {t(`Don't have an account? Create your account, it takes less than a minute.`)}
                </p>
            </div>

            <Form noValidate validated={validated} onSubmit={(event) => onSubmit(event)}>
                <FieldEditor 
                    type={FieldEditorType.Email}
                    field="email"
                    label={t('EMAIL')}
                    isRequired={true}
                    placeholder={t('Enter your email address')}
                    value={email}
                    onValueChanged={(field, value) => setEmail(value)}
                />
                <FieldEditor 
                    type={FieldEditorType.Password}
                    field="password"
                    label={t('PASSWORD')}
                    isRequired={true}
                    placeholder={t('ENTER_YOUR_PASSWORD')}
                    value={password}
                    onValueChanged={(field, value) => setPassword(value)}
                />
                <FieldEditor 
                    type={FieldEditorType.Password}
                    field="password2"
                    label={t('YOUR_PASSWORD_AGAIN')}
                    isRequired={true}
                    placeholder={t('CONFIRM_YOUR_PASSWORD')}
                    value={password2}
                    onValueChanged={(field, value) => setPassword2(value)}
                />
                <FieldEditor 
                    type={FieldEditorType.Checkbox}
                    field="accept_terms"
                    label={t('I_ACCEPT_THE_TERMS_AND_CONDITIONS')}
                    isRequired={true}
                    value={areTermsAccepted}
                    onValueChanged={(field, value) => setAreTermsAccepted(value)}
                />

                <div className="mb-3 mb-0 text-center">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {t('SIGN_UP')}
                    </Button>
                </div>
            </Form>
        </AuthContainer>
    );
}

export default RegistrationView;