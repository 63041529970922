import PixieTranslationGenerated, { PixieTranslationGeneratedEntityManager } from './generated/PixieTranslation.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class PixieTranslationEntityManager extends PixieTranslationGeneratedEntityManager<PixieTranslation>
{
    constructor()
    {
        super({
            createEntity: () => new PixieTranslation(),
            fields: [
                { name: 'slug', type: BusinessEntityFieldType.VarChar },
            ]
        });
    }
}

export class PixieTranslation extends PixieTranslationGenerated
{
    slug: string = '';

    static manager: PixieTranslationEntityManager = new PixieTranslationEntityManager();
}

export default PixieTranslation;