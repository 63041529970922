import { useTranslate } from '@xFrame4/components/Hooks';
import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';

interface DashboardProps
{

}

const DashboardView: FunctionComponent<DashboardProps> = (props) =>
{
    const t = useTranslate();

    const load = async () => 
    {
        
    };

    useEffect(() => {
        load();
    }, []);

    
    /** Render */
    return (
        <>
            
        </>
    );
}

export default DashboardView;