import { FunctionComponent } from 'react';
import React from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';
import Pixie from '@business/pixies/Pixie';
import { GridColumns } from '@mui/x-data-grid';
import PixieAddEditForm from './PixieAddEditForm';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import ImageCell from '@xFrame4/components/admin/ImageCell';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';

interface PixieViewProps
{

}

const PixieView: FunctionComponent<PixieViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<Pixie>(Pixie);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<Pixie>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'dateAdded', headerName: 'Date added' },
            { field: 'thumbnail', headerName: 'Thumbnail', renderCell: (params) => {
                return <ImageCell image={params.row.thumbnail} />
            }},
            { field: 'primaryLanguage', headerName: 'Primary language' },
            { field: 'title', headerName: 'Title', flex: 1, valueGetter: (params) => params.row.primaryTranslation?.title },
        ],
        [],
    );
    
    /** Render */
    return (
        <>
            {addEditEntity &&
            <PixieAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }
            
            <EntityView<Pixie>
                isVisible={addEditEntity === undefined}
                id="pixies"
                title="Pixies"
                manager={Pixie.manager}
                baseFilter={{}}
                pageSize={10}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'translation__slug', label: 'Slug', type: FieldEditorType.Text},
                ]}
                actions={[
                    
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ': ' + entity.primaryTranslation?.title,
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default PixieView;