import client from '@graphql/apollo-client';
import { ReadFacebookOgTagsFromUrlDocument, ReadFacebookOgTagsFromUrlQuery } from '@graphql/generated';

export class FacebookHelper
{
	/**
	* Create a host window for the Facebook share dialog.
	*/
	static createShareWindow(width: number, height: number) {
		var m_left = (window.innerWidth - width) / 2;
		var m_top = (window.innerWidth - height) / 2;
		var opts = 'status=1' +
		',width=' + width +
		',height=' + height +
		',top=' + m_top +
		',left=' + m_left;
		
		return opts;
	}
	
	/**
	* Share an URL on Facebook.
	* 
	* @param url 
	* @param imageUrl 
	* @param useFbSDK Use the FB JS SDK to share (app ID necessary).
	*/
	static shareOnFacebookFeed(url: string, imageUrl: string, useFbSDK = false) 
	{
		if (!useFbSDK) {
			var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url) + "&picture=" + imageUrl;
			window.open(shareUrl, 'share-facebook', FacebookHelper.createShareWindow(670, 560));
		}
		else {
			FB.ui({
				method: "share",
				display: "popup",
				href: url,
			}, (response) => console.log(response));
		}
	}

    static async readFacebookOgTagsFromUrl(url: string)
    {
        let { data } = await client.query<ReadFacebookOgTagsFromUrlQuery>({
            query: ReadFacebookOgTagsFromUrlDocument,
            variables: {
                url: url
            }
        });

        if (data.readFacebookOgTagsFromUrl != null)
        {
            return data.readFacebookOgTagsFromUrl;
        }
        else
        {
            return null;
        }
    }
}

export default FacebookHelper;