import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import FacebookUserProfile from '@business/scheduler/FacebookUserProfile';
import ScheduleLanguage from '@business/scheduler/ScheduleLanguage';
import SchedulePhase from '@business/scheduler/SchedulePhase';


export class FacebookPageGeneratedEntityManager<B extends FacebookPageGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'FacebookPage',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'profile', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'profileId', inputProperty: 'profile.id', relatedManager: FacebookUserProfile.manager },
				{ name: 'language', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'languageCode', inputProperty: 'language.code', relatedManager: ScheduleLanguage.manager },
				{ name: 'facebookId', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'accessToken', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'timezone', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'trackingCode', type: BusinessEntityFieldType.VarChar },
				{ name: 'isActive', type: BusinessEntityFieldType.Boolean },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'facebookPages',
        });
    }

}
export class FacebookPageGenerated extends BusinessEntity
{
	name!: string;
	profile!: FacebookUserProfile;
	language!: ScheduleLanguage;
	facebookId!: string;
	accessToken: string = '';
	timezone: number = 0;
	trackingCode: string = '';
	isActive: boolean = true;

	phases: ManyToManyCollection<SchedulePhase> = new ManyToManyCollection<SchedulePhase>(this, 'business/scheduler/FacebookPage', 'phases', 'business/scheduler/SchedulePhase', SchedulePhase.manager, 'facebookPage');
}

export default FacebookPageGenerated;