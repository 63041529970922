import React from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { AuthUser } from '@xFrame4/business/users/AuthUser';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import FacebookPage from '@business/scheduler/FacebookPage';
import FacebookUserProfile from '@business/scheduler/FacebookUserProfile';
import FacebookPageAddEditForm from './FacebookPageAddEditForm';
import PostToFeedForm from './PostToFeedForm';

interface FacebookPageViewProps
{

}

const FacebookPageView: FunctionComponent<FacebookPageViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<FacebookPage>(FacebookPage);
    const [pageToPostTo, setPageToPostTo] = React.useState<FacebookPage>();

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<FacebookPage>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Name', flex: 3 },
            { field: 'language', headerName: 'Language', valueGetter: (params) => params.row.language?.code },
            { field: 'timezone', headerName: 'Timezone', valueFormatter: (params) => params.value >= 0 ? `GMT + ${params.value}`: `GMT - ${params.value}`},
            { field: 'phases', headerName: 'Phases', valueGetter: (params) => params.row.phasesList.map(phase => phase.id).join(', '), flex: 2},
            { field: 'trackingCode', headerName: 'Tracking code' },
            { field: 'isActive', headerName: 'Active', type: 'boolean'},
        ],
        [],
    );

    /** Refresh the pages and the tokens for the logged in user. */
    const refreshPagesForUserProfile = async () => 
    {
        let facebookUserProfile = await FacebookUserProfile.manager.get({user_Id: AuthUser.current?.pk});
        let shortLivedAccessToken = await facebookUserProfile?.getAccessToken();
        if (shortLivedAccessToken)
        {
            await facebookUserProfile?.updateLongLivedAccessTokenAndUpdatePages(shortLivedAccessToken);
        }
    };

    /** Activate/deactivate page */
    const toggleIsActive = async (facebookPage: FacebookPage) =>
    {
        await facebookPage.setIsActive(!facebookPage.isActive);
    };

    /** Render */
    return (
        <>
            {addEditEntity &&
            <FacebookPageAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            {pageToPostTo &&
            <PostToFeedForm
                facebookPage={pageToPostTo}
                onClose={() => setPageToPostTo(undefined)}
            />
            }
            
            <EntityView<FacebookPage>
                isVisible={addEditEntity === undefined && pageToPostTo === undefined}
                id="facebook-pages"
                title="Facebook pages"
                manager={FacebookPage.manager}
                baseFilter={{}}
                pageSize={10}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    },
                    {
                        text: "Refresh tokens",
                        variant: 'danger',
                        onClick: () => refreshPagesForUserProfile()
                    },
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'isActive', label: 'Is active?', type: FieldEditorType.Checkbox},
                    {field: 'name_Icontains', label: 'Name', type: FieldEditorType.Text}
                ]}
                actions={[
                    {
                        icon: <ToggleOnIcon />,
                        label: 'Activate/deactivate',
                        onClick: (entity) => toggleIsActive(entity)
                    },
                    {
                        icon: <DynamicFeedIcon />,
                        label: 'Post to feed',
                        onClick: (entity) => setPageToPostTo(entity)
                    }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ': ' + entity.name,
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default FacebookPageView;