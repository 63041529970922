import { VerticalLayout } from '@components/hyper/layouts';
import { FunctionComponent } from 'react';

interface CustomAdminLayoutProps
{

}

const CustomAdminLayout: FunctionComponent<CustomAdminLayoutProps> = (props) =>
{
    /** Render */
    return (
        <>
            <VerticalLayout />
        </>
    );
}

export default CustomAdminLayout;